<template>
    <div
        id="update-resident"
        class="data-list-container"
    >
        <vs-button
            class="back-btn"
            type="border"
            icon="keyboard_arrow_left"
            @click="onBackBtnClick"
        >Retour</vs-button>

        <div class="form-header">Modification informations</div>
        <div class="form-legend">
            Renseigner ou modifier les
            informations du résident.
        </div>

        <HCard class="form">

            <!--step-selector
                :steps="steps"
                :selected="stepSelected"
                v-on:step-clicked="onStepClick"
            />-->

            <div class="form-title">Identité du résident</div>
            <div class="civility">
                <vs-radio
                    class="female"
                    v-model="resident.civility"
                    vs-name="civility"
                    vs-value="Mme"
                    data-cy="civility-female-radio"
                >Madame</vs-radio>
                <vs-radio
                    class="male"
                    v-model="resident.civility"
                    vs-name="civility"
                    vs-value="M"
                    data-cy="civility-male-radio"
                >Monsieur</vs-radio>
            </div>

            <div class="vx-row">
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">NOM</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            autofocus
                            class="form-input tw-w-full last-name"
                            v-model="resident.lastName"
                            type="text"
                            placeholder="Nom du résident(e)"
                            data-cy="lastName-field"
                            v-on:keyup="onLastNameChange"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.lastName"
                        >{{ fieldErrors.lastName }}</div>
                    </div>
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Numéro de chambre</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.roomNumber"
                            type="text"
                            placeholder="Chambre attitré au résident"
                            data-cy="room-field"
                            v-on:keyup="onRoomChange"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.roomNumber"
                        >{{ fieldErrors.roomNumber }}</div>
                    </div>
                </div>
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Prénom</label>
                            <div class="name-legend"></div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            v-model="resident.firstName"
                            type="text"
                            placeholder="Prénom du résident(e)"
                            data-cy="firstName-field"
                            v-on:keyup="onFirstNameChange"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.firstName"
                        >{{ fieldErrors.firstName }}</div>
                    </div>
                </div>
            </div>

            <div v-if="isSettingsEnabled" class="vx-row">
                <div class="vx-col tw-w-full">
                    <div class="form-field">
                        <HCheckbox
                            class="checkbox"
                            data-cy="suggestion-check"
                            v-model="isSuggestionDisabled"
                        >
                            Ne souhaite plus être contacté par happydom.
                        </HCheckbox>
                    </div>
                </div>
            </div>

            <div class="form-separator"></div>

            <div class="form-title">Contact dans la résidence</div>

            <div class="vx-row">
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Email</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            :disabled="$acl.not.check('isEhpadManager')"
                            v-model="resident.contactEmail"
                            type="text"
                            data-cy="email-field"
                            placeholder="nomdumail@chro-ehpad.com"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.email"
                        >{{ fieldErrors.email }}</div>
                    </div>
                </div>
                <div class="vx-col sm:tw-w-1/2 tw-w-full">
                    <div class="form-field">
                        <div class="form-name">
                            <label class="name-label">Numéro de téléphone</label>
                            <div class="name-legend">Facultatif</div>
                        </div>
                        <vs-input
                            class="form-input tw-w-full"
                            :disabled="$acl.not.check('isEhpadManager')"
                            v-model="resident.contactPhoneNumber"
                            type="text"
                            placeholder="+33"
                            data-cy="phone-field"
                            @focus="onPhoneFocus"
                            v-on:keyup="onPhoneChange"
                        />
                        <div
                            class="form-field-error"
                            v-if="fieldErrors.phone"
                        >{{ fieldErrors.phone }}</div>
                    </div>
                </div>
            </div>

            <div class="form-actions">
                <vs-button
                  class="cancel-btn"
                  type="border"
                  data-cy="cancel-btn"
                  @click="onCancelBtnClick"
                >Annuler</vs-button>
                <vs-button
                    class="save-btn"
                    data-cy="save-btn"
                    @click="onSaveBtnClick"
                >Enregistrer</vs-button>
            </div>

        </HCard>

    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import moment from 'moment'

import {
    capitalize
} from '@/modules/strings'
import Regexes from '@/constants/regexes'
import Routes from '@/constants/routes'

import {
    HCard,
    HCheckbox
} from '@happytal/bo-ui-library'

export default {
    name: 'UpdateResident',
    props: [
        'resident_id'
    ],
    components: {
        HCard,
        HCheckbox
    },
    data () {
        return {
            locked: false,
            resident: {
                institutionId: 0,
                civility: 'Mme',
                firstName: '',
                lastName: '',
                roomNumber: '',
                contactEmail: '',
                contactPhoneNumber: ''
            },
            isSuggestionDisabled: false,
            stepSelected: 'identity',
            steps: [
                { id: 'identity', label: 'etape 1' },
                { id: 'infos', label: 'etape 2' },
                { id: 'bank', label: 'etape 3' }
            ],
            fieldErrors: {},
            loaded: false
        }
    },
    computed: {
        isSettingsEnabled () {
            return !this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isFamily'
            ])
        }
    },
    methods: {
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        onPhoneFocus (e) {
            if (!this.resident.phone) {
                this.resident.phone = '+33'
            }
        },
        onFirstNameChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }

            const firstName = this.resident.firstName
            if (firstName) {
                this.resident.firstName = capitalize(firstName, true)
            }
        },
        onLastNameChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }
            const lastName = this.resident.lastName
            if (lastName) {
                this.resident.lastName = lastName.toUpperCase()
            }
        },
        onPhoneChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }
            const phone = this.resident.contactPhoneNumber
            if (phone) {
                this.resident.phone = phone.slice(0, 12)
            }
        },
        onRoomChange (e) {
            const charCode = e.which || e.keyCode || e.key
            if (charCode < 48 || charCode > 90) {
                return
            }
            const room = this.resident.roomNumber
            if (room) {
                this.resident.roomNumber = room.slice(0, 15)
            }
        },
        isFieldValid () {
            this.fieldErrors = {}
            const lastName = this.resident.lastName
            if (!lastName || !Regexes.LastName.test(lastName)) {
                this.fieldErrors.lastName = `Le nom est invalide.`
                return false
            }
            const firstName = this.resident.firstName
            if (!firstName || !Regexes.FirstName.test(firstName)) {
                this.fieldErrors.firstName = `Le prénom est invalide.`
                return false
            }
            const phone = this.resident.contactPhoneNumber
            if (phone && !Regexes.Phone.test(phone)) {
                this.fieldErrors.phone = `Le numéro de téléphone est invalide.`
                return false
            }
            const roomNumber = this.resident.roomNumber
            if (roomNumber && !Regexes.RoomNumber.test(roomNumber)) {
                this.fieldErrors.roomNumber = `Le numéro de chambre est invalide.`
                return false
            }
            const email = this.resident.contactEmail
            if (email && !Regexes.Email.test(email)) {
                this.fieldErrors.email = `L'email est invalide.`
                return false
            }
            return true
        },
        goToResident () {
            this.$router.push({
                name: Routes.ResidentProfile,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onBackBtnClick (e) {
            this.goToResident()
        },
        onCancelBtnClick (e) {
            this.goToResident()
        },
        onSaveBtnClick (e) {
            if (this.locked || !this.isFieldValid()) {
                return
            }
            this.locked = true
            this.resident.institutionId = parseInt(this.getInstitutionId(), 10)
            this.resident.enableSuggestion = !this.isSuggestionDisabled
            this.$store.dispatch('dataResidents/updateResident', {
                resident: this.resident
            })
            .then((response) => {
                this.locked = false
                const resident = _.get(response, 'data.resident', {})
                const residentId = _.get(resident, 'id', null)
                console.log('resident', resident)
                if (!resident.enableSuggestion) {
                    this.$store.commit('dataResidents/RESET_RESIDENT_OPENED_SUGGESTION_COUNT', residentId)
                }
                this.showInfoToast(`Le résident a bien été modifié.`)
                this.goToResident()
            },
            (error) => {
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
                this.locked = false
            })
        },
        /*onStepClick (step) {
            this.stepSelected = step.id
        },*/
        getUserId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : 5000,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    mounted () {
        this.loaded = false
        async.series([
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        })
                        .then(() => {
                            callback(null)
                        })
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                const resident = this.getResidentById(this.resident_id)
                if (!resident) {
                    this.$router.push('/error-404')
                    return
                }
                this.resident = {
                    id: resident.id,
                    institutionId: resident.institutionId,
                    civility: resident.civility,
                    firstName: resident.firstName,
                    lastName: resident.lastName,
                    roomNumber: resident.roomNumber,
                    contactPhoneNumber: resident.contactPhoneNumber,
                    contactEmail: resident.contactEmail
                }
                this.isSuggestionDisabled = !resident.enableSuggestion
                this.locked = false
                this.loaded = true
            }
        )
    }
}
</script>

<style lang="scss">
#update-resident {
    .back-btn {
        margin: 0px 0px 20px 0px;
    }
    .civility {
        display: flex;
        margin: 33px 0px 0px 0px;

        .male {
            margin: 0px 0px 0px 52px;
        }
        .female {

        }
    }
    .cancel-btn {
        margin: 0px 21px 0px 0px;
    }
    .save-btn {

    }
}
</style>
